import * as React from "react";
import Layout from "../components/layout";
import { link } from "./contact.module.css";

const ContactPage = () => {
  return (
    <Layout hasLongInner>
      <h3>Contact</h3>

      <p>
        <strong>Email:</strong> <a className={link} href="mailto:help@rckive.com">help@rckive.com</a><br />
        <strong>Instagram DM:</strong> <a className={link} href="https://instagram.com/rckive_official/">@rckive_official</a>
      </p>

      <p>
        Willem van Weldammelaan 133<br />
        1082KT Amsterdam<br />
        The Netherlands
      </p>

      <p>
        <strong>KVK:</strong> 80563082<br />
        <strong>VAT:</strong> NL861717508B01
      </p>
    </Layout>
  );
};

export default ContactPage;
